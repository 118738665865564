<script setup lang="ts">
import { toTypedSchema } from '@vee-validate/zod'
import { createReusableTemplate } from '@vueuse/core'
import { useForm } from 'vee-validate'
import { z } from 'zod'

const { values, setFieldValue, handleSubmit, handleReset } = useForm({
  validationSchema: toTypedSchema(
    z.object({
      name: z.string().min(1, 'Name erforderlich'),
      email: z.string().email('Ungültige Mail-Adresse'),
      message: z.string().min(1, 'Nachricht erforderlich').max(500, 'Nachricht zu lang'),
      privacy: z.boolean().refine((v) => v, 'Einverständnis erforderlich'),
    }),
  ),
  initialValues: {
    name: '',
    email: '',
    message: '',
    privacy: false,
  },
})

const toast = useToast()

const submit = handleSubmit(async (v) => {
  useTRPC()
    .contact.contactForm.mutate(v)
    .then(() =>
      toast.add({
        color: 'green',
        title: 'Nachricht gesendet',
        description: 'Wir werden uns so schnell wie möglich bei Ihnen melden.',
      }),
    )
    .catch((err) => {
      toast.add({
        color: 'red',
        title: 'Nachricht konnte nicht gesendet werden',
        description: err.message,
      })
    })
})

const [DefineTemplate, ReuseTemplate] = createReusableTemplate()
</script>

<template>
  <AlPadding>
    <UCard
      :ui="{
        body: { padding: 'px-6 py-8 sm:py-12 sm:px-8', base: 'h-full' },
        background: 'shadow-card',
        ring: '',
        rounded: 'rounded-xl',
      }"
    >
      <DefineTemplate>
        <div>
          <img src="/contact.svg" alt="Steffen Schuster" class="h-52 w-auto object-contain" />
          <h5 class="mt-4 text-2xl font-semibold">Fragen? Kontaktiere uns gern!</h5>
          <p class="mt-4 text-xs leading-6">
            atelierluft wird Deine personenbezogenen Daten ausschließlich für die Bearbeitung Deiner
            Anfrage verwenden. Eine Weitergabe an Dritte erfolgt nur, wenn es für die Erfüllung
            dieser Aufgabe erforderlich ist. Nähere Hinweise findest Du in den Hinweisen zum
            Datenschutz. Du hast jederzeit das Recht der Nutzung Deiner Daten zu widersprechen. Eine
            Bearbeitung Deines Anliegens ist dann jedoch nicht mehr möglich.
          </p>
        </div>
      </DefineTemplate>

      <div class="grid gap-16 xl:grid-cols-2">
        <div class="flex flex-col gap-4 pr-0 sm:pr-20 2xl:pr-40">
          <h3 class="font-sans-header text-3xl uppercase text-al-primary-300">Kontakt</h3>
          <div class="xl:hidden">
            <ReuseTemplate />
          </div>

          <form
            class="flex flex-col gap-4"
            @submit.prevent="(event) => submit(event)"
            @reset.prevent="() => handleReset()"
          >
            <AlTextInput label="Name" name="name" required secondary />
            <AlTextInput
              label="Mail-Adresse"
              name="email"
              required
              :input="{ type: 'email' }"
              secondary
            />
            <AlTextAreaInput label="Nachricht" name="message" required secondary />
            <UCheckbox
              :model-value="values.privacy"
              label="Einverständnis zur Verarbeitung Ihrer Daten"
              required
              @update:model-value="(value) => setFieldValue('privacy', value)"
            />

            <AlSlideoutButton type="submit" variant="secondary">Absenden</AlSlideoutButton>
          </form>
        </div>
        <div class="hidden xl:block">
          <ReuseTemplate />
        </div>
      </div>
    </UCard>
  </AlPadding>
</template>
